*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App{
  overflow: hidden;
}

@media only screen and (min-width: 500px){
  html{
    max-width: 500px;
    margin: 0 auto;
  }
}

button{
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}